import React from 'react';
import { createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#283659' },
    secondary: { main: '#C9D5DF' },
  },
  typography: {
    fontFamily: [
      'Nunito',
      'open sans',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'body': {
          backgroundColor: '#fff',
        },
        'input:-internal-autofill-selected': {
          backgroundColor: 'unset',
        },
        '*': {
          /* width */
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '5px',
          },
          /* Track */
          '&::-webkit-scrollbar-track': {
            background: '#fafafa',
            //   'box-shadow': `inset 5px 5px 5px '#000', inset -5px -5px 5px '#000'`,
            'border-radius': '1rem',
          },
          /* Handle */
          '&::-webkit-scrollbar-thumb': {
            background: '#C9D5DF',
            //   'box-shadow': `5px 5px 5px '#000', -5px -5px 5px '#000'`,
            'border-radius': '1rem',
          },
        },
      },
    },
  },
});

const MuiProvider = ({ children }) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(theme);
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ maxWidth: 480, margin: 'auto', overflow: 'hidden' }}>
        <CssBaseline />
        {children}
      </div>
    </ThemeProvider>
  );
};

export default MuiProvider;
