import {
	GET_RELATION_DATA,
	GET_DETAIL_RELATION,
	DELETE_DETAIL_RELATION,
	DELETE_ONE_RELATION,
	TOGGLE_DETAIL_MODAL,
	TOGGLE_DETAIL_MODAL_CONFIRM,
} from '../types';

import api from '../../utils/Api';

export const getDataRelation = (id) => (dispatch) => {
	let fetchApi;
	if (JSON.parse(localStorage.getItem('profile')).role === 'admin')
		fetchApi = 'getDetailRelation';
	else fetchApi = 'getRelationData';

	return api[fetchApi](id)
		.then((json) => json.data)
		.then((res) => dispatch({ type: GET_RELATION_DATA, payload: res }))
		.catch((err) => console.log(err));
};
// .finally(() => console.log('get data finished'));

export const getDetailRelation = (id) => (dispatch) =>
	api
		.getDetailRelation(id)
		.then((json) => json.data)
		.then((res) => {
			const { foto, ...rest } = res;
			return dispatch({ type: GET_DETAIL_RELATION, payload: rest });
		});

export const deleteDetailRelation = () => (dispatch) =>
	dispatch({ type: DELETE_DETAIL_RELATION });

export const deleteOneRelation = (id) => ({ type: DELETE_ONE_RELATION, id });

export const toggleDetailModal = () => ({ type: TOGGLE_DETAIL_MODAL });

export const toggleDetailModalConfirm = () => ({
	type: TOGGLE_DETAIL_MODAL_CONFIRM,
});
