/* eslint-disable no-case-declarations */
import { GET_ROUTES, EXCLUDE_ADMIN_ONLY_ROUTE, ROUTE_INDEX } from '../types';

const initialState = {
  routes: [],
  authorizedRoutes: [],
  defaultRoute: '',
  routeIndex: 1,
  slideDirection: 'right',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ROUTES:
      const defaultRoute = action.payload.find((el) => el.default).path;
      const authorizedRoutes = action.payload.filter((el) => el.authorized);
      return {
        ...state,
        routes: action.payload,
        defaultRoute,
        authorizedRoutes,
      };
    case EXCLUDE_ADMIN_ONLY_ROUTE:
      const excluded = state.routes.filter((el) => {
        return el.adminOnly !== true;
      });
      const excludesAuthorizedRoutes = excluded.filter((el) => el.authorized);

      return {
        ...state,
        routes: excluded,
        authorizedRoutes: excludesAuthorizedRoutes,
      };
    case ROUTE_INDEX:
      const nextRoute = state.routeIndex > action.index ? 'right' : 'left';
      return {
        ...state,
        routeIndex: action.index,
        slideDirection: nextRoute,
      };
    default:
      return state;
  }
}
