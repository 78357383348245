/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useContext } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import SwipeableRoutes from 'react-swipeable-routes';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { LOG_IN } from '../redux/types';
import { getRoutes } from '../redux/actions/Utils.action';
import { getForms } from '../redux/actions/AddNewRelations.action';

// data for redux
import dataRoutes from '../data/routes';
import formFields from '../data/addNewRelationForm.json';

// For secured route
import SecuredRoute from './SecuredRoute';

// Context for auth logic
import AuthContext from '../context/AuthContext';

// components
import AppBar from '../components/appBar';

// Container
import Login from '../container/Login';
import NotFound from '../container/NotFound';

const Secure = () => {
  const { routes, authorizedRoutes, defaultRoute, ...restUtils } = useSelector(
    (state) => state.utils,
  );
  return (
    <>
      <AppBar
        routes={routes}
        authorizedRoutes={authorizedRoutes}
        defaultRoute={defaultRoute}
        {...restUtils}
      />

      <SwipeableRoutes
        containerStyle={{
          height: 'calc(100vh - 104px)',
        }}
        // replace
      >
        {authorizedRoutes.map(({ path, component: Component }) => (
          <SecuredRoute key={path} path={path} component={Component} />
        ))}
      </SwipeableRoutes>
      <Redirect to={defaultRoute} />
    </>
  );
};

const Routes = ({ location }) => {
  const { defaultRoute } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const reFetchRoute = location.pathname === '/login';

  const token = localStorage.getItem('token');
  const profile = localStorage.getItem('profile');

  useEffect(() => {
    dispatch(getForms(formFields));
    if (token && profile) {
      dispatch({
        type: LOG_IN,
        payload: JSON.parse(profile),
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getRoutes(dataRoutes));
    if (token && profile) {
      history.push(defaultRoute);
    }
    // eslint-disable-next-line
  }, [reFetchRoute]);

  return (
    <Switch>
      <Route
        path='/login'
        render={(props) => <Login auth={auth} {...props} />}
      />

      <SecuredRoute component={Secure} />

      <Route
        path='*'
        component={() => <NotFound privateRoute={defaultRoute} />}
      />
    </Switch>
  );
};

export default Routes;

// const AnimatedRoute = ({ routes, authorizedRoutes, ...rest }) => {
// 	const slideDirection = useSelector((state) => state.utils.slideDirection);
// 	return (
// 		<React.Fragment>
// 			<AppBar routes={routes} authorizedRoutes={authorizedRoutes} {...rest} />
// 			<Switch>
// 				{authorizedRoutes.map(({ path, component: Component }) => (
// 					<Route exact key={path} path={path}>
// 						{({ match, ...rest }) => (
// 							// <Slide
// 							// 	in={match != null}
// 							// 	direction={slideDirection}
// 							// 	timeout={500}
// 							// 	unmountOnExit
// 							// >
// 							<div style={{ width: '100%', margin: 'auto' }}>
// 								<Component match={match} {...rest} />
// 							</div>
// 							// </Slide>
// 						)}
// 					</Route>
// 				))}
// 			</Switch>
// 		</React.Fragment>
// 	);
// };
