import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { getDataMember } from '../redux/actions/DataMember.action';
import api from '../utils/Api';
import Loader from '../components/loader';

const MembersComponent = lazy(() => import('../components/members'));

class Members extends Component {
  componentDidMount() {
    api.getAllMembers().then((res) => {
      this.props.getDataMember(res.data);
    });
  }

  render() {
    return (
      <Suspense fallback={<Loader />}>
        <MembersComponent data={this.props.dataMember} />
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  dataMember: state.dataMember.dataMember,
});

export default connect(mapStateToProps, { getDataMember })(Members);
