/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
// import { lazy } from 'react';

// const Relations = lazy(() => import('../container/Relations'));
// const Setting = lazy(() => import('../container/Setting'));
// const NewRelations = lazy(() => import('../container/NewRelations'));
// const NewMember = lazy(() => import('../container/NewMember'));
// const Login = lazy(() => import('../container/Login'));
// const NotFound = lazy(() => import('../container/NotFound'));
// const Member = lazy(() => import('../container/Members'));

import Relations from '../container/Relations';
import Setting from '../container/Setting';
import NewRelations from '../container/NewRelations';
import NewMember from '../container/NewMember';
import Login from '../container/Login';
import NotFound from '../container/NotFound';
import Member from '../container/Members';

const dataRoutes = [
  {
    name: 'Setting',
    path: '/setting',
    component: Setting,
    authorized: true,
  },
  {
    name: 'Data Relasi',
    path: '/data-relasi',
    component: Relations,
    authorized: true,
    default: true,
  },
  {
    name: 'Tambah Relasi',
    path: '/tambah-relasi',
    component: NewRelations,
    authorized: true,
  },
  {
    name: 'Tambah Anggota',
    path: '/tambah-anggota',
    component: NewMember,
    authorized: true,
    adminOnly: true,
  },
  {
    name: 'Data Anggota',
    path: '/data-anggota',
    component: Member,
    authorized: true,
    adminOnly: true,
  },
  { name: 'Login', path: '/login', component: Login, authorized: false },
  {
    name: 'NotFound',
    path: '*',
    component: NotFound,
  },
];

export default dataRoutes;
