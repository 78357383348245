import {
	GET_RELATION_DATA,
	GET_DETAIL_RELATION,
	DELETE_DETAIL_RELATION,
	DELETE_ONE_RELATION,
	TOGGLE_DETAIL_MODAL,
	TOGGLE_DETAIL_MODAL_CONFIRM,
} from '../types';

const initialState = {
	data: [],
	detail: {},
	detailModal: false,
	detailModalConfirm: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_RELATION_DATA:
			return {
				...state,
				data: action.payload,
			};
		case GET_DETAIL_RELATION:
			return {
				...state,
				detail: action.payload,
			};
		case DELETE_DETAIL_RELATION:
			return {
				...state,
				detail: {},
			};
		case DELETE_ONE_RELATION:
			const filter = state.data.filter((el) => el.id_relasi !== action.id);
			return {
				...state,
				data: filter,
			};
		case TOGGLE_DETAIL_MODAL:
			return {
				...state,
				detailModal: !state.detailModal,
			};
		case TOGGLE_DETAIL_MODAL_CONFIRM:
			return {
				...state,
				detailModalConfirm: !state.detailModalConfirm,
			};
		default:
			return state;
	}
};
