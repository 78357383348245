/* eslint-disable no-underscore-dangle */
import axios from 'axios';

// let _token = null;
// let _profile = null;

export const errorHandler = (cb) => (res) =>
  cb(res).catch((err) => console.log(err));

class Auth {
  constructor(history) {
    this.history = history;
    this.base = process.env.REACT_APP_API_ENDPOINT;
  }

  login(values, { enqueueSnackbar, closeSnackbar, defaultRoute, formik }, cb) {
    enqueueSnackbar(`Loading...`, { variant: 'info' });
    // debugger;
    // console.log('auth');
    return axios
      .post(`${this.base}/api/login`, values)
      .then((res) => {
        closeSnackbar();
        if (typeof res.data === 'string') throw res.data;
        cb(res.data.data);
        localStorage.setItem('token', res.data.api_key);
        localStorage.setItem('profile', JSON.stringify(res.data.data));
        localStorage.setItem('role', res.data.data.role);
        return res.data.data;
      })
      .then((data) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(data);
        }
        enqueueSnackbar(`Selamat datang ${data.username}`, {
          variant: 'success',
        });
      })
      .then(() => this.history.push(defaultRoute))
      .catch((err) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(err);
        }
        cb(err);
        closeSnackbar();
        // this.setState({ loading: false });
        const errMsg = 'Username atau password salah atau tidak terdaftar';
        enqueueSnackbar(errMsg, { variant: 'error' });
        if (typeof err === 'string') {
          formik.setFieldError('password', errMsg);
          formik.setFieldError('username', errMsg);
        }
      });
  }

  isAuthenticated() {
    return (
      localStorage.getItem('token') &&
      localStorage.getItem('profile') &&
      localStorage.getItem('role')
    );
  }

  isAdmin() {
    return JSON.parse(localStorage.getItem('profile')).role === 'admin';
  }

  logout(cb) {
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    localStorage.removeItem('role');
    this.history.push('/login');
    if (cb) {
      cb();
    }
  }
}

export default Auth;
