import React, { useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import AuthContext from '../../context/AuthContext';

function Logo({ classes, pathname }) {
  const auth = useContext(AuthContext);

  return (
    <Grid
      container
      item
      justify='center'
      alignItems='center'
      xs={12}
      className={classes.container}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{ isolation: 'isolate' }}
        width='100'
        height='40'
        viewBox='643.882 7.25 152.235 81'
      >
        <g fontFamily='Nunito' fontSize='20' fontWeight='900'>
          <text
            transform='translate(643.882 43.895) scale(1.812)'
            style={{ fontVariantLigatures: 'none' }}
            fill='#F5F7F9'
          >
            SINERGI
          </text>
          <text
            transform='translate(643.882 75.455) scale(1.812)'
            style={{ fontVariantLigatures: 'none' }}
            fill='#C9D5DF'
          >
            MUSLIM
          </text>
        </g>
      </svg>
      {localStorage.getItem('token') && !pathname.includes('setting') && (
        <Button
          color='inherit'
          className={classes.logout}
          onClick={() => {
            auth.logout();
          }}
        >
          LogOut
        </Button>
      )}
    </Grid>
  );
}

export default Logo;
