import { GET_DATA_MEMBER } from '../types';

const initialState = {
  dataMember: [],
};

// eslint-disable-next-line consistent-return
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DATA_MEMBER:
      return {
        ...state,
        dataMember: action.payload,
      };
    default:
      return state;
  }
}
