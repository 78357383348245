/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent, Suspense, lazy } from 'react';
import { withSnackbar } from 'notistack';

import loginInput from '../data/loginInput.json';

import { connect } from 'react-redux';
import { setProfile } from '../redux/actions/Login.action';
import Extractor from '../utils/extractor';
import Loader from '../components/loader';

const AuthComponent = lazy(() => import('../components/auth'));

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.extractor = new Extractor(loginInput);
    this.state = {
      loading: false,
    };
  }

  handleSubmit = (values, formik) => {
    const {
      setProfile,
      enqueueSnackbar,
      closeSnackbar,
      defaultRoute,
    } = this.props;

    this.setState({ loading: true });
    this.props.auth.login(
      values,
      { closeSnackbar, enqueueSnackbar, defaultRoute, formik },
      (data) => {
        this.setState({ loading: false });

        setProfile(data);
      },
    );
  };

  render() {
    const { loading } = this.state;

    return (
      <Suspense fallback={<Loader />}>
        <AuthComponent
          list={loginInput}
          loading={loading}
          initialValues={this.extractor.initialValues}
          handleSubmit={this.handleSubmit}
        />
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  defaultRoute: state.utils.defaultRoute,
});

const mapDispatchToProps = (dispatch) => ({
  setProfile: (data) => dispatch(setProfile(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(Login));
