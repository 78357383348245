import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Routes from './routes/routes';
import MuiProvider from './styles';
import ReduxStore from './redux/ReduxStore';
import Auth from './utils/Auth';
import AuthContext from './context/AuthContext';

function App({ history }) {
  const [anchor, setAnchor] = useState({
    vertical: 'bottom',
    horizontal: 'left',
  });

  const [auth] = useState(new Auth(history));

  useEffect(() => {
    if (window.innerWidth < 600) {
      setAnchor({ vertical: 'top', horizontal: 'right' });
    }
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      <ReduxStore>
        <MuiProvider>
          <SnackbarProvider maxSnack={3} anchorOrigin={anchor}>
            <Route component={Routes} />
          </SnackbarProvider>
        </MuiProvider>
      </ReduxStore>
    </AuthContext.Provider>
  );
}

export default App;
