/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';

class Extractor {
  constructor(json /* object */, multiply /* Array matrix */) {
    this.initialValues = {};
    this.json = { ...json };
    this.arrayValues = [];
    this._initialization.bind(this)(multiply);
  }

  _initialization(multiply = []) {
    /*
		  Check if there's second parameter, and the parameter is Array,
		  and parameter have pairs identifier and count
	  */
    if (multiply && Array.isArray(multiply) && multiply.length) {
      /* Loop the array */
      let array;
      multiply.forEach((identifier) => {
        array = new Array(identifier[1]);
        // eslint-disable-next-line no-plusplus
        for (let x = 0; x < identifier[1]; x++) {
          const clone = { ...this.json[identifier[0]] };
          identifier[2].forEach((key) => {
            if (key === 'label') {
              clone[key] += ` ${x + 1}`;
            } else {
              clone[key] += `_${x + 1}`;
            }
          });
          array[x] = clone;
        }
        // console.log(identifier);
        /* assign new value to the current value */
        this.json[identifier[0]] = array;
      });
    }

    /* Assign flatten array */
    this.arrayValues = this._getFlatArray(this.json);
    // console.log(this);
    /* Initial values for controlled input */
    this.initialValues = this.arrayValues
      .map((el) => el.field)
      .reduce((obj, v) => {
        obj[v] = '';
        return obj;
      }, {});

    return this;
  }

  _getFlatArray(obj) {
    // Get flatten array from object
    return Object.values(obj).flat();
  }
}

Extractor.propTypes = {
  json: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  multiply: PropTypes.arrayOf(PropTypes.array),
};

export default Extractor;
