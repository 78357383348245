import React, { Component, lazy, Suspense } from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { addNewMember } from '../redux/actions/AddNewMember.action.js';

import addNewMemberJSON from '../data/AddNewMember.json';
import Extractor from '../utils/extractor.js';
import Loader from '../components/loader/index.js';

const AddNewMember = lazy(() => import('../components/addNewMember'));

class NewMember extends Component {
  constructor(props) {
    super(props);
    this.extractor = new Extractor(addNewMemberJSON);
  }

  handleSubmit = (data, opt, formik) => {
    const { enqueueSnackbar, closeSnackbar, addNewMember } = this.props;
    const { resetForm } = opt;
    enqueueSnackbar('Loading...', { variant: 'info' });

    addNewMember(data)
      .then((res) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(this.extractor.initialValues)) {
          if (typeof res.data[key] === 'object')
            // eslint-disable-next-line
            throw { field: key, err: res.data[key].join() };
        }
        // console.log(res);
        closeSnackbar();
        enqueueSnackbar('Berhasil ditambahkan', {
          variant: 'success',
        });
        resetForm();
      })
      .catch(({ err, field }) => {
        closeSnackbar();
        formik.setFieldError(field, err);

        enqueueSnackbar(err, {
          variant: 'error',
        });
      });
  };

  render() {
    return (
      <Suspense fallback={<Loader />}>
        <AddNewMember
          formFields={Object.values(this.extractor.json)}
          initialValues={this.extractor.initialValues}
          handleSubmit={this.handleSubmit}
        />
      </Suspense>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addNewMember: (data) => dispatch(addNewMember(data)),
});

export default connect(null, mapDispatchToProps)(withSnackbar(NewMember));
