/* eslint-disable import/prefer-default-export */
// Add New Relations types
export const GET_FORMS = 'GET_FORMS';
export const ADD_NEW_FIELD = 'ADD_NEW_FIELD';
export const DELETE_NEW_FIELD = 'DELETE_NEW_FIELD';

// Relation data
export const GET_RELATION_DATA = 'GET_RELATION_DATA';
export const GET_DETAIL_RELATION = 'GET_DETAIL_RELATION';
export const DELETE_DETAIL_RELATION = 'DELETE_DETAIL_RELATION';
export const DELETE_ONE_RELATION = 'DELETE_ONE_RELATION';
export const TOGGLE_DETAIL_MODAL = 'TOGGLE_DETAIL_MODAL';
export const TOGGLE_DETAIL_MODAL_CONFIRM = 'TOGGLE_DETAIL_MODAL_CONFIRM';

// Login
export const LOG_IN = 'LOG_IN';

// Routes
export const GET_ROUTES = 'GET_ROUTES';
export const EXCLUDE_ADMIN_ONLY_ROUTE = 'EXCLUDE_ADMIN_ONLY_ROUTE';
export const ROUTE_INDEX = 'ROUTE_INDEX';

// Get Members
export const GET_DATA_MEMBER = 'GET_DATA_MEMBER';
