import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    maxWidth: 480,
    margin: '0 auto',
    padding: '.5rem',
    position: 'relative',
  },
  logout: {
    position: 'absolute',
    right: '2rem',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  link: {
    textDecoration: 'none',
    '& span': {
      ' & div': {
        color: theme.palette.secondary.main,
        whiteSpace: 'nowrap',
      },
    },
  },
  linkActive: {
    '& span': {
      position: 'relative',
      fontWeight: 900,
      '& div': {
        color: theme.palette.primary.main,
      },
      '&::after': {
        content: "''",
        height: 25,
        width: 25,
        backgroundColor: 'rgba(40, 54, 89, 0.1)',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
        borderRadius: '50%',
      },
    },
  },
  nav: {
    display: 'flex',
    maxWidth: 480,
    overflow: 'auto',
    margin: 'auto',
    flexWrap: 'nowrap',
    // padding: '.5rem 0',
    position: 'relative',
    /* width */
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#fafafa',
      //   'box-shadow': `inset 5px 5px 5px '#000', inset -5px -5px 5px '#000'`,
      'border-radius': '1rem',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      //   'box-shadow': `5px 5px 5px '#000', -5px -5px 5px '#000'`,
      'border-radius': '1rem',
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));
