import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = ({ privateRoute = '/', publicRoute = '/login' }) => (
  <div
    style={{
      height: '100vh',
      width: '100%',
      display: 'grid',
      placeItems: 'center',
      position: 'fixed',
      zIndex: 10000,
      background: 'white',
      top: 0,
      left: 0,
    }}
  >
    <h1>
      Nothing here.. back to{' '}
      {localStorage.getItem('token') ? (
        <Link to={privateRoute}>Home?</Link>
      ) : (
        <Link to={publicRoute}>Login Page?</Link>
      )}
    </h1>
  </div>
);

export default NotFound;
