import { combineReducers } from 'redux';
import addNewRelation from './addNewRelation.reducer';
import relationData from './relationData.reducer';
import profile from './profile.reducer';
import utils from './utils.reducer';
import dataMember from './dataMember.reducer';

export default combineReducers({
	addNewRelation,
	relationData,
	profile,
	utils,
	dataMember,
});
