/* eslint-disable react/jsx-fragments */
import React from 'react';
import { AppBar } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import Logo from './Logo';
import Tabs from './Tabs';

// eslint-disable-next-line import/extensions
import appBarStyles from './appBar.styles.js';

export const maxHeightAppBar = 104;

function AppBarComp(props) {
  const { pathname } = useLocation();
  const classes = appBarStyles();

  return (
    <React.Fragment>
      <AppBar
        position='fixed'
        style={{ boxShadow: 'unset', maxHeight: maxHeightAppBar }}
      >
        <Logo classes={classes} pathname={pathname} {...props} />
        <Tabs {...props} />
      </AppBar>
      <div style={{ height: maxHeightAppBar }} />
    </React.Fragment>
  );
}

export default AppBarComp;
