import { LOG_IN } from '../types';

export default (state = { data: {} }, action) => {
	switch (action.type) {
		case LOG_IN:
			return {
				...state,
				data: action.payload,
			};
		default:
			return state;
	}
};
