/* eslint-disable import/prefer-default-export */
import api from '../../utils/Api';
import { GET_FORMS, ADD_NEW_FIELD, DELETE_NEW_FIELD } from '../types';

export const getForms = (data) => ({
  type: GET_FORMS,
  payload: data,
});

export const addNewField = (index) => ({
  type: ADD_NEW_FIELD,
  index,
});

export const addNewRelation = (data) => api.postAddNewRelation(data);

export const deleteNewField = (index) => ({
  type: DELETE_NEW_FIELD,
  index,
});
