import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

// import formFields from '../data/addNewRelationForm.json';
import {
  getForms,
  addNewRelation,
} from '../redux/actions/AddNewRelations.action';
import Extractor from '../utils/extractor';
import addNewRelationForm from '../data/addNewRelationForm.json';
import Loader from '../components/loader';

const AddNewRelation = lazy(() => import('../components/addNewRelation'));

class NewRelations extends Component {
  constructor(props) {
    super(props);
    this.extractor = new Extractor(addNewRelationForm, [
      ['nama_bidang', 3, ['field']],
      ['nama_perusahaan', 3, ['field']],
    ]);
  }

  handleSubmit = (val, opt) => {
    const { id_anggota, enqueueSnackbar, closeSnackbar } = this.props;

    const { resetForm } = opt;
    enqueueSnackbar('Loading...', { variant: 'info' });

    const {
      nama_bidang_1,
      nama_bidang_2,
      nama_bidang_3,
      nama_perusahaan_1,
      nama_perusahaan_2,
      nama_perusahaan_3,
      photo,
      ...rest
    } = val;

    const nama_bidang = this.combineValues(
      nama_bidang_1,
      nama_bidang_2,
      nama_bidang_3,
    );
    const nama_perusahaan = this.combineValues(
      nama_perusahaan_1,
      nama_perusahaan_2,
      nama_perusahaan_3,
    );
    const finalValue = { nama_bidang, nama_perusahaan, id_anggota, ...rest };

    if (process.env.NODE_ENV !== 'production') {
      console.log(finalValue);
    }
    addNewRelation(finalValue).then((res) => {
      closeSnackbar();
      enqueueSnackbar(JSON.stringify(res.data.Berhasil), {
        variant: 'success',
      });
      resetForm();
    });
  };

  combineValues = (...args) => {
    const values = args.filter(
      (el) => el !== '' && el !== undefined && el !== null,
    );
    return values;
  };

  deleteFieldAndDeleteFormikValue = (i, formik) => {
    const { forms, deleteNewField: deleteField } = this.props;
    const value = forms.find((el) => el.field === forms[i].field);
    formik.setFieldValue(value.field, '');
    deleteField(i);
  };

  componentDidMount() {
    this.props.getForms({
      forms: Object.values(this.extractor.json),
      default: addNewRelationForm,
      // initialValues: this.extractor.initialValues,
    });
  }

  render() {
    const { forms } = this.props;

    return (
      <Suspense fallback={<Loader />}>
        <AddNewRelation
          forms={forms}
          initialValues={this.extractor.initialValues}
          handleSubmit={this.handleSubmit}
        />
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  forms: state.addNewRelation.forms,
  id_anggota: state.profile.data.id_anggota,
});

export default connect(mapStateToProps, {
  getForms,
  addNewRelation,
})(withSnackbar(NewRelations));
