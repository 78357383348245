import { GET_ROUTES, EXCLUDE_ADMIN_ONLY_ROUTE } from '../types';

export const getRoutes = (payload) => (dispatch) => {
  dispatch({ type: GET_ROUTES, payload });

  if (localStorage.getItem('role') === 'Anggota') {
    dispatch({ type: EXCLUDE_ADMIN_ONLY_ROUTE });
  }
};

export const excludeAdminOnlyRoute = () => ({ type: EXCLUDE_ADMIN_ONLY_ROUTE });
