import {
  GET_FORMS,
  //  ADD_NEW_FIELD, DELETE_NEW_FIELD
} from '../types';

const initialState = {
  forms: [],
  default: [],
  // initialValues: {},
};

// eslint-disable-next-line consistent-return
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FORMS:
      return {
        ...state,
        forms: action.payload.forms,
        default: action.payload.default,
        // initialValues: action.payload.initialValues,
      };
    // case ADD_NEW_FIELD:
    //   const forms = [...state.forms];
    //   const num = state.forms.filter((el) => el.label === 'Bidang').length;
    //   if (num < 3) {
    //     forms.splice(action.index + 1, 0, {
    //       'field': `nama_bidang_${num + 1}`,
    //       'label': 'Bidang',
    //       'type': 'text',
    //       'id': num + 1,
    //     });
    //   }
    //   return {
    //     ...state,
    //     forms,
    //   };
    // case DELETE_NEW_FIELD:
    //   const newState = {
    //     ...state,
    //     forms: state.forms.filter((el) => el !== state.forms[action.index]),
    //   };
    //   return newState;
    default:
      return state;
  }
}
