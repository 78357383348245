import axios from 'axios';

const base = process.env.REACT_APP_API_ENDPOINT;

const Api = {
	login: (data) => axios.post(`${base}/api/login`, data),
	getRelationData: (id) => axios.get(`${base}/api/relasi/per-id-anggota/${id}`),
	postAddNewRelation: (data) => axios.post(`${base}/api/relasi/create`, data),
	postAddNewMember: (data) => axios.post(`${base}/api/register`, data),
	getDetailRelation: (id) =>
		axios.get(`${base}/api/relasi${id ? `/${id}` : ''}`),
	getAllMembers: () => axios.get(`${base}/api/anggota`),
	deleteOneRelation: (id) => axios.delete(`${base}/api/relasi/destroy/${id}`),
};

export default Api;
