import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs as MaterialTabs, Tab as MaterialTab } from '@material-ui/core';
import RouterLink from '../../routes/RouterLink';

const Tabs = withStyles((theme) => ({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: theme.palette.primary.main,
	},
}))(MaterialTabs);

const Tab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		'&:hover': {
			color: theme.palette.primary.main,
			opacity: 1,
		},
		'&$selected': {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightMedium,
		},
		'&:focus': {
			color: theme.palette.primary.main,
		},
	},
	selected: {},
}))((props) => <MaterialTab disableRipple {...props} />);

function TabsStyled(props) {
	return <Tabs {...props}>{props.children}</Tabs>;
}

function TabStyled({ to, value, ...props }) {
	return (
		<Tab component={RouterLink} to={to ?? value} value={value} {...props} />
	);
}

export { TabsStyled as Tabs, TabStyled as Tab };
