import React from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { Tabs, Tab } from '../tabs';
import { useTabsWithRouter } from '../../hooks/useTabsWithRouter';
import { ROUTE_INDEX } from '../../redux/types';

import appBarStyles from './appBar.styles.js';

function TabsComponent({ routes, authorizedRoutes, defaultRoute }) {
	const dispatch = useDispatch();
	const classes = appBarStyles();
	const tabValue = useTabsWithRouter(
		authorizedRoutes.map((el) => el.path),
		defaultRoute,
	);

	return (
		<Grid
			container
			item
			xs={12}
			alignItems='center'
			style={{
				backgroundColor: '#fff',
				color: '#000',
			}}
		>
			<div className={classes.nav}>
				<Tabs
					value={tabValue}
					variant='scrollable'
					scrollButtons='auto'
					aria-label='sinergi-muslim-tab'
				>
					{authorizedRoutes.map((el, index) => (
						<Tab
							key={el.name}
							value={el.path}
							label={el.name}
							onClick={() => dispatch({ type: ROUTE_INDEX, index })}
						/>
					))}
				</Tabs>
			</div>
		</Grid>
	);
}

export default TabsComponent;
