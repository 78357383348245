import React, { Component, lazy, Suspense } from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';

import {
  getDataRelation,
  getDetailRelation,
  deleteDetailRelation,
  deleteOneRelation,
  toggleDetailModal,
  toggleDetailModalConfirm,
} from '../redux/actions/RelationData.action';

import api from '../utils/Api';
import Loader from '../components/loader';

const Relations = lazy(() => import('../components/relations'));

class Home extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { loading: false, deleteLoading: false };
  }

  deleteOneRelation = (id) => {
    this.setState({ deleteLoading: true });

    api
      .deleteOneRelation(id)
      .then((res) => {
        this.setState({ deleteLoading: false });
        this.props.deleteOneRelationAction(id);
        this.props.toggleDetailModal();
        this.props.toggleDetailModalConfirm();
        this.props.deleteDetail();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ deleteLoading: false });
        this.props.enqueueSnackbar(err, { variant: 'error' });
      });
  };

  componentDidMount() {
    this._isMounted = true;

    const { getData, id, cardsData } = this.props;
    if (localStorage.getItem('token') && localStorage.getItem('profile')) {
      if (!cardsData.length) {
        this.setState({ loading: true });
      }
      getData(id).then(() => {
        if (this._isMounted) this.setState({ loading: false });
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      cardsData,
      detail,
      getDetail,
      deleteDetail,
      toggleDetailModal,
      toggleDetailModalConfirm,
    } = this.props;

    return (
      <Suspense fallback={<Loader />}>
        <Relations
          cardsData={cardsData}
          cardDetail={detail}
          getDetail={getDetail}
          deleteDetail={deleteDetail}
          loading={this.state.loading}
          deleteOneRelation={this.deleteOneRelation}
          toggleDetailModal={toggleDetailModal}
          toggleDetailModalConfirm={toggleDetailModalConfirm}
        />
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  cardsData: state.relationData.data,
  id: state.profile.data.id_anggota,
  detail: state.relationData.detail,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (id) => dispatch(getDataRelation(id)),
  getDetail: (id) => dispatch(getDetailRelation(id)),
  deleteDetail: () => dispatch(deleteDetailRelation()),
  deleteOneRelationAction: (id) => dispatch(deleteOneRelation(id)),
  toggleDetailModal: () => dispatch(toggleDetailModal()),
  toggleDetailModalConfirm: () => dispatch(toggleDetailModalConfirm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Home));
