import React, { Component, lazy, Suspense } from 'react';

import { connect } from 'react-redux';

import Loader from '../components/loader';

const SettingComp = lazy(() => import('../components/setting'));

class Setting extends Component {
  render() {
    const { history, profile } = this.props;
    return (
      <Suspense fallback={<Loader />}>
        <SettingComp history={history} profile={profile} />
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile.data,
});

export default connect(mapStateToProps, null)(Setting);
